<!-- FieldManager vue -->

<template lang="pug">
div.field-ctn(v-if="display", v-bind:class="[field.type, {'isDeprecated': field.deprecated}, {'invisible-setting':field.invisible}, {'notApply': (isTemplate||includedQueryField) && !applyField }]")
	p.text-hover(v-bind:class="{'isTooLong': dynlabel && dynlabel.length > 18}") {{dynlabel}}

	span.description-bull(v-if="field.description", v-on:click="displayDescription", v-bind:class="{'buildOnly': field.buildOnly}") {{field.buildOnly ? "!" : "?" }} 
	span.description-bull2(v-if="field.resume", v-on:click="displayResume") ?

	div.btApply(v-if="includedQueryField", v-bind:class="{'notApply': !applyField }", v-on:click.stop="toggleApplyField()")
	
	div.field-wrapper(v-if="widget=='memory'")
		memory-field(:field="field", :fieldvalue="value", :bloc="bloc")

	div.field-wrapper(v-if="widget=='curve2d'")
		curve(:field="field", :fieldvalue="value", :bloc="bloc" )

	div.field-wrapper(v-if="widget=='object'")
		object-field(:field="field", :fieldvalue="value", :bloc="bloc")

	div.field-wrapper(v-if="widget=='geo'")
		geo-field(:field="field", :fieldvalue="value", :bloc="bloc", :dynlabel="dynlabel", :connection-target="connectionTarget", :connection-change-handler="connectionChangeHandler", :geomodalState="state.main.geomodal" )

	div.field-wrapper(v-else)
		span.text(v-if="dynlabel && widget !== 'memory'", v-bind:class="{'disabled':field.disabled}") {{dynlabel}}
			//span.description-bull(v-if="field.description", v-on:click="displayDescription") ?

		textarea-field(v-if="widget=='textarea'", :field="field", :fieldvalue="value", :placeholder="placeholder")

		div(v-bind:class="{'param-ctn':field.type=='param' && !groupfield, 'input-fields': field.type=='field' || groupfield, 'groupfield': groupfield, 'memory-link-field': hasMemoryLink, 'textarea-outputs':widget=='textarea', 'disabled':field.disabled }")

			// BOOLEAN
			boolean-field(v-if="widget=='boolean'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// STRING
			string-field(v-if="widget=='string'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// JSON
			json-field(v-if="widget=='jsontype'", :field="field", :fieldvalue="value", :bloc="bloc", :placeholder="placeholder")

			// Geo & 2D Shape
			string-field(v-if="widget=='geoshape'", :field="field", :fieldvalue="value", :placeholder="'{GeoJSON}'")
			string-field(v-if="widget=='shape2d'", :field="field", :fieldvalue="value", :placeholder="'[[x,y],[x,y], … [x,y]]'")

			// FLOAT
			float-field(v-if="widget=='number' || widget=='float'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// INT
			int-field(v-if="widget=='int'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// DISPLAY UNIT
			display-unit-field(v-if="widget=='displayunit'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// PERCENT
			percent-field(v-if="widget=='percent'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// ANGLE
			angle-field(v-if="widget=='angle'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// CLOCK
			clock-field(v-if="widget=='clock'", :field="field", :fieldvalue="value", :timemetric="inspectorOption")

			// COLOR
			color-field(v-if="widget=='color'", :field="field", :fieldvalue="value", :bloc="bloc", :placeholder="placeholder")

			// SELECT
			select-field(v-if="widget=='select'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// VECTOR-2D
			vector2d(v-if="widget=='vector2'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// VECTOR-3D
			vector3d(v-if="widget=='vector3'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// EULER
			euler-field(v-if="widget=='euler'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// ARRAY
			array-field(v-if="widget=='array'", :field="field", :fieldvalue="value", :bloc="bloc", :placeholder="placeholder", :inspectorField="inspectorField", :target='target')

			// ASSET
			asset-field(v-if="widget=='asset'", :field="field", :fieldvalue="value", :bloc="bloc", :placeholder="placeholder")

			// SECRET
			secret-field(v-if="widget=='secret'", :field="field", :fieldvalue="value", :bloc="bloc", :placeholder="placeholder", :user="state.user")

			// PARENT NODE
			select-node-field(v-if="widget=='select-node'", :field="field", :fieldvalue="value", :bloc="bloc")

			// TEMPLATE NODE
			select-template-field(v-if="widget=='select-template-id'", :field="field", :referent="value", :bloc="bloc")
			select-template-field(v-if="widget=='select-template-name'", :field="field", :referent="value", :bloc="bloc")

			// CALCULATED
			calculated-field(v-if="widget=='calculated'")

			// TIMESTAMP
			date-time-picker-field(v-if="widget=='timestamp'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// SESSION PATTERN
			session-pattern-list-field(v-if="widget=='session-pattern-list'", :field="field", :fieldvalue="value", :placeholder="placeholder")

			// OLD
			//oldfield(v-else, :field="field", :values="values", :bloc="bloc", :groupfield="groupfield", :target="target")

			span(v-if="field.type=='field' && !ui")

				// INPUT
				span.plug.checkbox(v-bind:class="{'disabled':!field.connection || !field.connection.in.pluggable || field.connection.in.disabled,'selected':connectionTarget.in }", v-on:click="connectionChangeHandler(field, 'in')")
					input(type="checkbox", v-model="connectionTarget.in")

				// OUTPUT
				span.plug.checkbox(v-bind:class="{'disabled':!field.connection || !field.connection.out.pluggable || field.connection.out.disabled,'selected':connectionTarget.out}", v-on:click.stop="connectionChangeHandler(field, 'out')")
					input(type="checkbox", v-model="connectionTarget.out")

				div.clear
			div.clear



			div.rond-bts-ctn.memory-link(v-if="hasMemoryLink", v-bind:class="{'activated': memLinkActivated }")
				div.action-bts
					div.action-bt.bt-link(v-on:click.stop.prevent="toggleMemoryLink()")

		div.clear

	div.clear

	div.field-wrapper(v-if="hasMemoryLink && memLinkActivated")
		memory-field(:field="field", :fieldvalue="value", :bloc="bloc", :mem-link="field.memoryLink" )

</template>

<script>
	import { stores } from '../../../stores/MainStore.js';


	import actionManager from '../../../actions';
	import * as helper from '../../../helper';

	// INPUTS
	import BooleanField from "./BooleanField.vue";
	import StringField from "./StringField.vue";
	import TextareaField from "./TextareaField.vue";
	import FloatField from "./FloatField.vue";
	import IntField from "./IntField.vue";
	import PercentField from "./PercentField.vue";
	import AngleField from "./AngleField.vue";
	import ClockField from "./ClockField.vue";
	import ColorField from "./ColorField.vue";
	import Curve2DField from "./Curve2DField.vue";
	import SelectField from "./SelectField.vue";
	import Vector2DField from "./Vector2DField.vue";
	import Vector3DField from "./Vector3DField.vue";
	import EulerField from "./EulerField.vue";
	import DisplayUnitField from "./DisplayUnitField.vue";
	import ArrayField from "./ArrayField.vue";
	import AssetField from "./AssetField.vue";
	import MemoryField from "./MemoryField.vue";
	import ObjectField from "./ObjectField.vue";
	import SelectNodeField from "./SelectNodeField.vue";
	import SelectTemplateField from "./SelectTemplateField.vue";
	import CalculatedField from "./CalculatedField.vue";
	import DateTimePickerField from "./DateTimePickerField.vue";
	import SessionPatternListField from "./SessionPatternListField.vue";
	import JSONField from "./JSONField.vue";
	import GeoField from "./GeoField.vue";
	import SecretField from './SecretField.vue';


	export default {

		props: ['field', 'bloc', 'groupfield', 'target', 'state', 'inspectorField', 'inspectorOption'],

		components: {
			BooleanField,
			StringField,
			TextareaField,
			FloatField,
			IntField,
			PercentField,
			AngleField,
			ClockField,
			ColorField,
			SelectField,
			DisplayUnitField,
			curve: Curve2DField,
			vector2d: Vector2DField,
			vector3d: Vector3DField,
			EulerField,
			ArrayField,
			AssetField,
			MemoryField,
			ObjectField,
			SelectNodeField,
			SelectTemplateField,
			CalculatedField,
			DateTimePickerField,
			SessionPatternListField,
			JsonField: JSONField,
			GeoField,
			SecretField
		},

		data() {
			return {
				currentDynType: null,
				hasMemoryLink: false,
				memLinkActivated: false,
				// TODO: prefers pass app.state to childrens, $root or $children references could change
				// (migration from v1: this.$root)
				app: this.$root.$children[0],
				displayUnitField: "%",
			}

		},

		computed: {

			isTemplate: function() {
				return (this.bloc.value.level == 'template')
			},

			includedQueryField: function() {
				if (this.app.state.inspector && this.app.state.inspector.queryMode === true) {
					if (this.field.name.indexOf('module') !== 0)
						return true;
				}

				return false;
			},

			blocIncluded: function() {
				if (this.field.includedField) {
					let blocIncluded = helper.block.getBlockById(this.app.state.project, this.field.includedField);
					return blocIncluded;
				}

				return null;
			},

			value: function() {
				return this.field.widget.indexOf('select-template-')==-1 ? helper.block.getField(this.bloc, this.field.name) : this.bloc.value.ref_template;
			},

			memoryLinked : function(){
				if( this.value && this.value.memoryLinked ){
					this.memLinkActivated = true;
					return this.value.memoryLinked;
				}

				if (this.field.includedField && this.blocIncluded) {
					let fieldUi = helper.block.getField(this.blocIncluded, this.field.name);
					return fieldUi ? fieldUi.memoryLinked : null;
				}

				return null;
			},

			applyField: function() {

				if (this.value && this.value.apply !== undefined) {
					return this.value.apply;
				}

				return false;

			},

			placeholder: function() {

				if (this.field.includedField && this.blocIncluded) {
					let fieldUi = helper.block.getField(this.blocIncluded, this.field.name);
					// Computed property "memoryLinked" was assigned to but it has no setter (since migration Vue v1 to v2)
					// Does this make sens to assigned this property ?
					//this.memoryLinked = fieldUi.memoryLinked;
					return fieldUi ? fieldUi.value : null;
				}

				return null;
			},

			connectionTarget: function() {
				let field = helper.block.getField(this.bloc, this.field.name);
				return Object.assign({}, field);

			},

			display: function() {
				if (this.field.type === 'param' && (!this.field.conditions || this.field.conditions.length === 0))
					return true;

				return this.bloc.custom.fields[this.field.name] ? this.bloc.custom.fields[this.field.name].display : false ;
			},

			widget: function() {
				// force "calculated" widget if the field is only an output
				const isOuputOnly = this.field.connection && this.field.connection.in.disabled && !this.field.connection.out.disabled;
				return isOuputOnly ? 'calculated' : this.field.widget.toLowerCase();
			},

			ui: function() {
				if (this.bloc && this.bloc.value.type == "object")
					return true;
				return false;
			},

			dynlabel : function(){
				let capitalizeFirstLetter = (string) => {
					return string.charAt(0).toUpperCase() + string.slice(1);
				};

				if( this.field.dynLabel !== undefined ){
					let targetField = helper.block.getField( this.bloc, this.field.dynLabel );
					if( targetField && targetField.value )
						return capitalizeFirstLetter(targetField.value);
				}
				return this.field.label
			},

			// dynamicDisplayConditionField: function(){
			// 	return this.field.dynamicType.field.value
			// },

			// dynamicTypeFieldConditionHasChanged: function(){
			// 	let fieldValue = helper.block.getField(this.bloc, this.field.dynamicType.field).value;
			// 	console.log('fieldValue', fieldValue)
			// 	return fieldValue = helper.block.getField(this.bloc, this.field.dynamicType.field).value;
			// }

		},

		created: function() {

			// Computed property "blocIncluded" was assigned to but it has no setter (since migration Vue v1 to v2)
			// Does this make sens to assigned this property ?
			//if (this.field.includedField)
			//	this.blocIncluded = helper.block.getBlockById(this.app.state.project, this.field.includedField);

			if (this.field.memoryLink !== undefined)
				this.hasMemoryLink = true;

			if( this.value && this.value.memoryLinked !== undefined && this.value.memoryLinked !== null )
				this.memLinkActivated = true;

			this.checForkDynamicType();
		},

		methods: {

			selectHasChanged: function(newVal){
				this.$parent.selectHasChanged(newVal);
			},

			valueUpdated: function(fieldUpdated, newValue) {
				if( this.field.updateConfig === true ){
					this.$xpConfig[fieldUpdated.name] = newValue;
					actionManager.trigger('main:updateXpConfig', {name:fieldUpdated.name, value:newValue});
				}

				if (this.bloc) {
					actionManager.trigger('inspector:updateValue', {
						subaction: this.bloc.value.type,
						targetId: this.bloc.value._id,
						fieldName: fieldUpdated.name,
						newValue: newValue
					});
				}
			},

			unitUpdated: function (unit) {
				this.displayUnitField = unit
				return unit
			},

			toggleApplyField: function() {
				let newVal = !this.applyField;
				actionManager.trigger('inspector:updateApplyFieldQueryMode', {
					targetId: this.bloc.value._id,
					fieldName: this.field.name,
					apply: newVal
				})


				if (newVal === false) {
					actionManager.trigger('inspector:connectionChange', {
						subaction: "removeIn",
						bloc_id: this.bloc.value._id,
						fieldName: this.field.name
					});


					actionManager.trigger('inspector:connectionChange', {
						subaction: "removeOut",
						bloc_id: this.bloc.value._id,
						fieldName: this.field.name

					});


				}

			},

			connectionChangeHandler: function(fieldUpdated, side) {

				// Si champ desactivé, on fait rien
				if (!fieldUpdated.connection[side].pluggable)
					return;


				let subaction = (!this.connectionTarget[side] ? "add" : "remove") + side.substr(0, 1).toUpperCase() + side.substr(1).toLowerCase();

				let target = this.bloc.value._id;
				actionManager.trigger('inspector:connectionChange', {
					subaction,
					bloc_id: target,
					fieldName: fieldUpdated.name
				});


			},

			getDynamicType: function() {
				if (this.field.dynamicType) {
					let fieldValue = helper.block.getField(this.bloc, this.field.dynamicType.field).value;
					if (this.field.dynamicType.property) {
						let entity = helper.block.getEntityByIDInWholeProject(this.app.state.project, fieldValue);

						if (entity && entity.value)
							entity = entity.value;

						if (entity && entity[this.field.dynamicType.property])
							fieldValue = entity[this.field.dynamicType.property];

					}

					return fieldValue;
				}

				return null;

			},

			checForkDynamicType: function() {
				if (this.field.dynamicType) {
					// add watcher for the field targetted to configure the type for this field
					// if the value changes, we need to check if the current type changed and delete links already created
					this.currentDynType = this.getDynamicType();
					// if (this.currentDynType && this.field.dynamicWidget) {
					// 	this.field.widget = this.currentDynType.toLowerCase()
					// }
					// this.$watch( 'this.values["'+ this.field.dynamicType.field +'"].value' , this.dynamicTypeChange , {deep:true} );
					//this.dynamicTypeChange(); // init with current value of the targetted field
				}
			},

			toggleMemoryLink: function(){
				this.memLinkActivated = !this.memLinkActivated;
				if( this.memLinkActivated === false ){
					this.onMemLinkUpdated( null );
				}
			},

			memLinkUpdated: function( value ){

				if (this.bloc)
					actionManager.trigger('inspector:updateMemoryLink', {
						targetId: this.bloc.value._id,
						fieldName: this.field.name,
						newValue: value
					});
			},

			displayDescription: function() {
				let unitName;
				
				switch (this.displayUnitField) {
					case 'px':
						unitName = 'Pixel';
						break;
					case 'calc':
						unitName = 'CSS calc() expression';
						break;
					default:
						unitName = 'Percent';
				}
				
				let type = this.field.coreFormat === "DisplayUnit" ? unitName : this.field.coreFormat;
				actionManager.trigger('main:modal:openModal', {title:this.dynlabel, text:this.field.description, type:type, continueButton: 'OK', cancelButton: '', icon: 'help', isBuildOnly: this.field.buildOnly })
			},

			displayResume: function(){
				actionManager.trigger('main:modal:openModal', {title:this.dynlabel, text:this.field.resume, continueButton: 'OK', cancelButton: '', icon: 'help' })
			},

			isTruncated: function(){
				Array.from(document.querySelectorAll('.text'))
				.forEach(element => {
					if(element.offsetWidth < element.scrollWidth){
						element.title = element.innerText;
					}
				})
			},

			dynAction: function(action, value) {
				const args = { ...action.args, ...{ value, bloc: this.bloc, group: this.groupfield, field: this.field }};
				actionManager.trigger(action.name, args);
			},
		},


	}
</script>

<style lang="stylus">

input.value, textarea.value
	color: var(--inputTextColor)!important
	float:left
	font-size: 11px
	background-color: var(--inputValue)
	border: 0
	border-radius: 2px
	text-align: left

textarea.value
	min-width: calc(100% - 20px)
	max-width: calc(100% - 20px)
	padding: 5px
	height: 60px
	margin: -10px 5px 5px 5px
	font-family: 'DinBold', 'ComfortaaLight';
	text-align left
	border-radius: 4px
	font-size: 14px

.color-picker
	position: absolute
	z-index: 1000
	top: 40px
	right: -1px
	display: none

	&.open
		display: block

.color-square
	border-radius: 4px
	margin-top: 5px
	margin-left: 5px
	margin-right: 0px
	width: 75px
	border: 0px
	position: relative
	display: inline-block
	height: 30px
	vertical-align: top
	cursor: pointer
	background-image: url(../../../assets/images/transparent.png)

	.color
		position absolute
		top 0
		left 0
		right 0
		bottom 0
		border-radius: 4px

.checkbox
	position: relative
	cursor:pointer
	width: 30px !important
	display: inline-block
	margin-top: 5px !important
	&:before
		content:''
		display:block
		position: absolute
		top: 0
		left: 0px
		width: 30px
		height: 30px
		background-color: var(--inputValue)
		border: 0
		border-radius: 4px
	&:after
		content:''
		display:block
		position: absolute
		top: 4px
		left: 4px
		width: 22px
		height: 22px
		background-color: var(--bgColor)
		-webkit-mask-image: url(../../../assets/images/check.svg)
		-webkit-mask-size: 16px
		-webkit-mask-repeat: no-repeat
		-webkit-mask-position: center
		mask-image: url(../../../assets/images/check.svg)
		mask-size: 16px
		mask-repeat: no-repeat
		mask-position: center
		border: 0
		border-radius: 2px
		opacity: 0

	&.disabled
		cursor: auto
		opacity: 0.2
		pointer-events: none

	&.selected
		&:after
			opacity: 1


	input
		visibility: hidden

	&.value
		margin-left: 56% !important

.title > .field-ctn:first-child > .field-wrapper
	line-height: 40px
	font-size:14px

.param-ctn
	display: inline-block
	position: absolute
	top: 5px
	right: 5px
	line-height: 26px
	width: 235px
	text-align: right

	.checkbox
		height: 30px
		width: 30px
		position: relative
		margin-top: 0px !important

	.textinput-ctn
		height: auto
		width: 170px
		position: relative

		&.memory
			width: 98%

		input.value
			display: block
			width: 155px
			//oldwidth: 160px
			float: right
			height: 30px
			margin: 0px
			padding: 0px
			font-family: 'DinBold', 'ComfortaaLight'
			font-size: 14px
			padding-left: 10px
			border-radius: 4px
			outline: none!important

		.unit
			top: 2px
			line-height: 23px

		&.has-unit
			display: block

			input.value
				width: 58%
				padding-right: 15%


.big-bt
	/*padding: 2px 4px 4px 5px*/

.disabled
	opacity: 0.2


.select-arrow
	display: inline-block
	position: absolute
	top: 0
	right: 10px
	font-family: 'DinRegular', 'ComfortaaLight'
	font-size: 10px
	margin-top: 0px
	z-index: 2
	height: 20px
	pointer-events: none
	background-color: var(--inputTextColor)
	-webkit-mask: url(../../../assets/images/arrowSelector.svg) no-repeat 50% 50%
	mask: url(../../../assets/images/arrowSelector.svg) no-repeat 50% 50%
	transition: linear all .1s !important
	background-size: contain
	background-repeat: no-repeat
	background-position: center
	width: 14px
	height: 30px
	color: transparent

.group .select-arrow
	top: 5px

select
	background-color: var(--inputValue)
	width: calc(100% - 5px)
	margin-right: 5px
	color: var(--inputTextColor) !important
	font-family: 'DinBold', 'ComfortaaLight'
	text-transform: capitalize
	font-size: 14px
	border: 0
	border-radius: 4px
	text-align: left
	display: inline-block
	height: 26px
	line-height: 26px
	vertical-align: top
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none
	padding-left: 10px
	padding-right: 10px
	z-index: 3
	display: inline-block
	vertical-align: top
	height: 30px
	margin-top: 5px
	margin-left: 5px
	outline: none!important

	&.memory
		width: 100%

	&:disabled
		opacity: 0.5
		margin-top: 5px

.param-ctn
	select
		vertical-align: middle
		margin-top: -9px
	.select-arrow
		top: 0px
		margin-right: -5px

input.input-file
	display: none

label.input-file-trigger
	display: inline-block
	width: 100%
	height: 40px
	margin: auto
	font-family: 'DinBold', 'ComfortaaLight'
	font-size: 14px
	line-height: 40px
	text-align: center
	background-color: #707070;
	color: #ffffff
	border-radius: 8px
	cursor: pointer
	margin-top: 10px



span.file-uploaded
	display: inline-block
	width: 86%
	height: 100%
	margin: auto


.textinput-ctn.setted
	padding-bottom: 23px
	position: relative

.file-action-bts
	display: block
	position: absolute
	top: 7px
	height: 23px
	right: 2px

	//bottom: 0px
	//left: 0px
	//width: 100%
	//height: 23px
	//display: inline-block
	//width: 12%
	//height: 100%

	.action-bt
		display: inline-block
		vertical-align: middle
		background-color: #ffffff
		border-radius: 20px
		width: 20px
		height: 20px
		margin-right: 3px
		margin-top: 3px
		float: left
		background-size: auto 60%
		background-repeat: no-repeat
		background-position: center center
		cursor: pointer

		&.bt-delete
			background-image: url(../../../assets/images/bt-trash.png)
		&.bt-view
			background-image: url(../../../assets/images/ico-eye.png)

		&:hover
			background-color: #58585B

			&.bt-delete
				background-image: url(../../../assets/images/bt-trash-white.png)
			&.bt-view
				background-image: url(../../../assets/images/ico-eye-white.png)

.hide
	display: none
	visibility: hidden
	opacity: 0

input.rtl
	direction: RTL !important
	text-align: right

input.fileinfo
	cursor: pointer

.notApply
	opacity: 0.3
	pointer-events:none

.btApply
	display: block
	width: 104px
	position absolute
	top 0
	left 0
	bottom 0
	background-color white
	opacity 0
	pointer-events: auto
	z-index: 1

	&.notApply
		width 100%


.memory-link-field
	position relative

.memory-link
	background-color: #FFF
	font-size: 12px
	color: #777
	height 20px
	position absolute
	background-color transparent
	opacity 0.3
	left 0
	top 0

	&.activated
		opacity 1

	.action-bts
		position: absolute
		right: 3px
		top: 0
		height: 100%
		width: 20px;
		transition: all 0.2s ease-in-out;

		.action-bt
			float: right;
			display: block;
			width: 20px;
			height: 100%;
			position: relative;
			transition: all 0.2s ease-in-out;

.textarea-outputs
	position: absolute
	top: 4px
	right: 10px


.textinput-ctn.has-unit
	.unit
		text-align: right
		margin-right: 5px
		&.disabled
			opacity: 0.2
			pointer-events: none

</style>
